import React from "react";
import heading from "../assests/about.h.png";
import about from "../assests/about.png";
import "../scss/about.scss";
const About = () => {
  return (
    <>
      <section id="about">
        <div className="container">
          <div className="img1" data-aos="fade-up">
            <img src={about} alt="" />
          </div>
          <div className="content">
            <div className="heading" data-aos="fade-up">

              <h2>ABOUT ISLAMIC GOLD DINAR</h2>

            </div>
            <p data-aos="fade-up">
              IGD Role in Ending Poverty: In the quest to alleviate poverty and empower the Ummah (Muslim community), innovative solutions are emerging within the realm of decentralized finance. Cryptocurrencies, with their potential for anonymity, financial inclusivity, and transparency, present a unique opportunity to make a positive impact. By leveraging crypto assets and promoting responsible financial practices, we can collectively work towards ending poverty within the community.
            </p>
            <a data-aos="fade-up" href="https://mobile.twitter.com/igdtoken" target="_blank">Join Community!</a>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;

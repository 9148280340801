import React from "react";
import {
  FaAccessibleIcon,
  FaDiscord,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPage4,
  FaTelegram,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { AiOutlineTwitter, AiOutlineTelegram } from "react-icons/ai";
import "../scss/footer.scss";
import { LinkRounded } from "@material-ui/icons";
import bac from "../assests/footer-bac.jpg";
import logo from "../assests/logo.png";

const Footer = () => {
  return (
    <>
      <footer id="footer">
        <div className="container">
          <div className="top" data-aos="fade-up">
            <div className="logo" >
              <a href="#">
                <img src={logo} alt="" />
              </a>
            </div>
          </div>
          <div className="bottom" data-aos="fade-up">
            <div className="icons">
              <a href="https://www.youtube.com/channel/UCgDgjTOo3Rucb6GYZybR9BA">
                <FaYoutube />
              </a>
              <a href="https://instagram.com/igdtoken?utm_medium=copy_link">
                <FaInstagram />
              </a>
              <a href="https://mobile.twitter.com/igdtoken">
                <FaTwitter />
              </a>

              <a href="https://discord.gg/UjRRDH9y">
                <FaDiscord />
              </a>
              <a href="http://linkedin.com/in/igd-token-89b336227">
                <FaLinkedin />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

import React, { useEffect, useState, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../scss/roadmap.scss";
import { roadmap } from "../assests/data";
import icon from "../assests/logo.png";

const Roadmap = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 100,
    });
    // how to check page distance from top
  }, []);

  return (
    <>
      <section id="roadmap" className="roadmap">
        <div className="heading" data-aos="fade-up">
          <h1> Roadmap</h1>
          <p>
            We're thrilled about the promising future of the Islamic Gold Dinar token. Dive into our comprehensive strategic plan to stay informed! We continuously enhance the user experience and encourage you to stay in the loop by following our social media channels. For a more in-depth understanding, be sure to review our whitepaper, which provides extensive details; the roadmap below offers only the key highlights.
          </p>
          <p>
            Cryptocurrencies offer immense potential in the fight against poverty within the Ummah. By leveraging the advantages of anonymity, liquidity, and smart contracts, we can collectively make a significant impact on poverty alleviation efforts. However, it is crucial to approach these initiatives with caution, ensuring transparency, accountability, and compliance with legal and ethical frameworks. Through responsible utilization of cryptocurrencies and fostering a culture of giving, we can pave the way towards a more prosperous and equitable future for the Ummah.
          </p>
        </div>
        <div className="container">
          <div className="roadmap__grid">
            {roadmap.map((item, i) => {
              return (
                <>
                  <div
                    className={`item-wrapper ${i % 2 == 0 ? "item-left" : "item-right"
                      }`}
                    data-aos="fade-up"
                  >
                    <div className={`item ${i % 2 == 0 ? "left" : "right"}`}>
                      <div className="icon">
                        <img src={icon} alt="" />
                      </div>
                      <h2>Phase {item.id}</h2>
                      <ul>
                        {item.points.map((item, i) => {
                          return <li key={i}>{item}</li>;
                        })}
                      </ul>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Roadmap;

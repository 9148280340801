import React from "react";
import "../scss/abouttwo.scss";
import about from "../assests/partners/Group 1.png";

const AboutTwo = () => {
  return (
    <>
      <section id="about">
        <div className="container">
          <div className="content">
            <div data-aos="fade-up" className="heading">
              {/* <h1>Innovative Payment Network</h1> */}
              <h2>USE-CASE OF IGD DIGITAL CASH</h2>
            </div>
            <p data-aos="fade-up">
              IGD Raffles can be conducted using blockchain technology, creating a fair and transparent mechanism for redistributing wealth within the community. Participants can contribute crypto assets and, in return, have the opportunity to win valuable prizes. The proceeds generated from these raffles can be allocated towards poverty alleviation efforts or invested back into the community.

            </p>
            <p data-aos="fade-up">The concept of IGD One Way Bet allows individuals to participate in investment opportunities while minimizing risk. By leveraging smart contracts, community members can pool their funds to invest in viable projects or businesses that create sustainable income streams. This inclusive approach empowers individuals to grow their wealth collectively, fostering economic independence and ultimately reducing poverty.  </p>
            <p data-aos="fade-up">IGD  Financial Loan (No Interest) Based: Promoting Ethical Borrowing
              Islamic principles discourage the charging of interest on loans. Cryptocurrencies provide an avenue to facilitate interest-free financial loans within the community. By utilizing smart contracts and decentralized lending platforms, individuals can access funds without the burden of interest, ensuring fair and ethical lending practices. This approach promotes financial inclusion and enables aspiring entrepreneurs to pursue their dreams without falling into the cycle of debt.</p>
            <a data-aos="fade-up" href="https://mobile.twitter.com/igdtoken" target="_blank">Join Community!</a>
          </div>
          <div className="img" data-aos="fade-up">
            <img src={about} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutTwo;

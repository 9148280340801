import React, { useEffect, useRef } from "react";
import { Parallax } from "react-parallax";
import "../scss/banner.scss";
import img1 from "../assests/bg/24783.jpg";
import heading from "../assests/heading.webp";
import logo from "../assests/logo.png";
import { MdKeyboardArrowRight } from "react-icons/md";
import pdf from "../assests/Whitepaper .pdf"
const Banner = () => {
  const imageRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (imageRef.current) {
        const rect = imageRef.current.getBoundingClientRect();
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;

        if (rect.top < window.innerHeight && rect.bottom > 0) {
          const scrollPercentage =
            (window.innerHeight - rect.top) / window.innerHeight;
          const rotationAngle = -90 + scrollPercentage * 90;

          imageRef.current.style.transform = `rotateY(${rotationAngle}deg)`;
          imageRef.current.style.transition = "transform 0.5s ease-out";
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <section id="banner">
        <div className="container">
          <div className="content">
            <div className="heading">
              <h1 data-aos="fade-up"> ISLAMIC GOLD DINAR</h1>

              <p data-aos="fade-up">
                One of the challenges many philanthropists face is the inability to give back anonymously. Cryptocurrencies offer a solution by allowing individuals to make donations and contribute to poverty alleviation efforts without revealing their identities. By utilizing blockchain technology, transactions can be conducted securely and privately, ensuring the preservation of both the donor's anonymity and the recipient's dignity.
              </p>
              <div className="flex__">
                <a data-aos="fade-up" href={pdf}>
                  Whitepaper
                </a>
                <a
                  data-aos="fade-up"
                  target="blank_"
                  href="https://presale.golddinar.co.uk"
                >
                  Buy on Presale
                </a>
              </div>
            </div>
          </div>
          <div className="img fade-up">
            <img className="fade-up" ref={imageRef} src={logo} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;

import React, { useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../scss/token.scss";
import Chart from "./Chart";
import token from "../assests/token.png"

// import { gsap } from "gsap";
// import { TimelineLite, Power3 } from "gsap/gsap-core";
// import { ScrollTrigger } from "gsap/all";
// gsap.registerPlugin(ScrollTrigger);
// const data = [2, 15, 3, 10, 12, 8, 4, 40];
const data = [
    {
        name: "Rewards",
        percent: "5%",
    },
    {
        name: "LP",
        percent: "5%",
    },
    {
        name: "Marketing",
        percent: "5%",
    },

];

const data2 = [
    {
        name: "Rewards",
        percent: "2%",
    },
    {
        name: "LP",
        percent: "3%",
    },
    {
        name: "Marketing",
        percent: "10%",
    },
];
const Token = ({ ease }) => {
    return (
        <section id="token">
            <div className="container">
                <div className="first">
                    <img src={token} style={{ width: "100%" }} alt="" data-aos="fade-up" />
                    {/* <Chart /> */}
                    <div className="percent" data-aos="fade-up">
                        <div className="h">
                            <span></span> <p>Liquidity</p>
                        </div>

                        <div className="h">
                            <span></span> <p>Marketing</p>
                        </div>
                        <div className="h">
                            <span></span> <p>Team</p>
                        </div>
                        <div className="h">
                            <span></span> <p>Advisors</p>
                        </div>
                    </div>
                </div>
                <div className="second">
                    <div className="heading" data-aos="fade-up">
                        <h1>Tokenomics</h1>
                    </div>
                    <div className="detial" data-aos="fade-up">
                        <div className="d">
                            <h1>Name:</h1>
                            <span>$IGD</span>
                        </div>
                        <div className="d">
                            <h1>Purchase methods accepted:</h1>
                            <span>BSC</span>
                        </div>
                    </div>
                    <div className="data-info" data-aos="fade-up">
                        <div className="item">
                            <p>Network</p>
                            <h3
                                style={{
                                    color: " rgb(0 0 0)",
                                }}
                            >
                                Binance Smart Chain
                            </h3>
                        </div>
                        <div className="item">
                            <p>Total Supply</p>
                            <h3
                                style={{
                                    color: "rgb(0 0 0)",
                                }}
                            >
                                2.2 billions
                            </h3>
                        </div>
                        <div className="item">
                            <p>Initial Market</p>
                            <h3
                                style={{
                                    color: " rgb(0 0 0) ",
                                }}
                            >
                                Cap BA
                            </h3>
                        </div>
                    </div>
                    <div className="datalist">
                        <div className="item" data-aos="fade-up">
                            <div className="head__">Buying - 15% Tax</div>
                            {data.map((a) => {
                                return (
                                    <div className="box" >
                                        <h2>{a.name}</h2>
                                        <span>{a.percent}</span>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="item" data-aos="fade-up">
                            <div className="head__">Selling - 15% Tax</div>
                            {data2.map((a) => {
                                return (
                                    <div className="box" >
                                        <h2>{a.name}</h2>
                                        <span>{a.percent}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="detial" data-aos="fade-up">
                        <div className="d">
                            <h1>CA:</h1>
                            <span>0x3b2181bb198258259b9256f8c4adc49f41f7e78e</span>
                        </div>

                    </div>
                    <div className="btn" data-aos="fade-up">
                        <a href="https://presale.golddinar.co.uk/">Buys Tokens</a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Token;

import React, { Component } from "react";

class LanguageSelector extends Component {
    componentDidMount() {
        const googleTranslateScript = document.createElement("script");
        googleTranslateScript.src =
            "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
        document.head.appendChild(googleTranslateScript);
        window.googleTranslateElementInit = this.initializeTranslate;
    }

    initializeTranslate = () => {
        new window.google.translate.TranslateElement(
            {
                pageLanguage: "en",
                includedLanguages: "en,fr,es,ar,ru,zh-CN",
                layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
            },
            "google_translate_element"
        );
    };

    render() {
        return (
            <div>
                <div className="custom-select">
                    {/* <select id="language-selector">
                        <option value="en">English</option>
                        <option value="fr">French</option>
                        <option value="es">Spanish</option>
                        <option value="ar">Arabic</option>
                        <option value="ru">Russian</option>
                        <option value="zh">Chinese</option>
                    </select> */}
                </div>
                <div id="google_translate_element"></div>
            </div>
        );
    }
}

class App extends Component {
    render() {
        return (
            <div>

                <LanguageSelector />

            </div>
        );
    }
}

export default App;

import React from "react";
import "../scss/moreinfo.scss";
import img1 from "../assests/fair-icon.png";
import img2 from "../assests/secure-icon.png";
import img3 from "../assests/open-icon.png";
const MoreInfo = () => {
  return (
    <>
      <section id="section-2">
        <div className="container">
          <div className="heading" data-aos="fade-up">
            {/* <h1>Innovative Payment Network</h1> */}
            <h2>How It Benefits The Community</h2>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation</p> */}
          </div>
          <div className="content">
            <div className="box-wrapper" data-aos="fade-up">
              <div className="box">
                <img src={img1} alt="" />
                <h2>NGO's</h2>
                <p>
                  With NGOs’ efforts strained by the pandemic it’s become clear that investing IGD digital cash is our only chance to revolutionize the way we give back .By using blockchain technology, we have a great opportunity ahead of us to help others without any restrictions.
                </p>
                {/* <a href="#">Read Mora</a> */}
              </div>
            </div>
            <div className="box-wrapper" data-aos="fade-up">
              <div className="box">
                <img src={img2} alt="" />
                <h2>Through Strategic Partnerships</h2>
                <p>
                  IGD provide an avenue for individuals to contribute to the liquidity pool, enabling the Ummah to benefit collectively. By encouraging community members to pool their funds, these liquidity pools can be used to finance projects, support initiatives aimed at poverty reduction. The concept of profit-sharing within the community can help ensure that the benefits are distributed equitably, amplifying the impact of these contributions.
                </p>
                {/* <a href="#">Read Mora</a> */}
              </div>
            </div>
            <div className="box-wrapper" data-aos="fade-up">
              <div className="box">
                <img src={img3} alt="" />
                {/* <h2>Easy to Transact</h2> */}
                <h2>Charity</h2>
                <p>
                  Zakat, the Islamic obligation of giving, plays a crucial role in combating poverty. Smart contracts built on blockchain technology can facilitate the collection and distribution of Zakat in a transparent and efficient manner. Through self-executing contracts, the community can ensure that Zakat funds are allocated appropriately, reaching those who are most in need. Smart contracts also enable automatic calculations and disbursements, reducing administrative burdens and ensuring the integrity of the Zakat system.
                </p>
                {/* <a href="#">Read Mora</a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MoreInfo;

import React from "react";
import i1 from "../assests/partners/yahoo finance.png";
import i2 from "../assests/partners/yahoo money.png";
import i3 from "../assests/partners/yahoo news.png";
import i4 from "../assests/partners/market watch.png";
import i5 from "../assests/partners/Digital-Journal-Logosm.png";
import i6 from "../assests/partners/benzinga.png";


import "../scss/parteners.scss";
const Parteners = () => {
  return (
    <>
      <section id="parteners">
        <div className="container">
          <div className="heading" data-aos="fade-up">
            <h2> MEDIA REPORTS</h2>
          </div>
          <div className="content">
            <a href="https://www.digitaljournal.com/pr/igd-digital-cash-is-all-set-to-take-the-global-cryptocurrency-market-by-storm">
              {" "}
              <img
                src={i5}
                alt=""
                data-aos="fade-up"
              />
            </a>
            <a href="https://www.yahoo.com/news/igd-digital-cash-set-global-115100849.html">
              <img
                src={i3}
                alt=""
                data-aos="fade-up"
              />
            </a>
            <a href="https://money.yahoo.com/igd-digital-cash-set-global-115100849.html">
              <img
                src={i2}
                alt=""
                data-aos="fade-up"
              />
            </a>
            <a href="https://finance.yahoo.com/igd-digital-cash-set-global-115100849.html">
              <img
                src={i3}
                alt=""
                data-aos="fade-up"
              />
            </a>

            <a href="https://www.marketwatch.com/press-release/igd-digital-cash-is-all-set-to-take-the-global-cryptocurrency-market-by-storm-2022-02-25?mod=search_headline">
              <img
                style={{ height: "40px" }}
                src={i4}
                alt=""
                data-aos="fade-up"
              />
            </a>
            <a href="https://www.benzinga.com/pressreleases/22/02/g25834320/igd-digital-cash-is-all-set-to-take-the-global-cryptocurrency-market-by-storm">
              <img
                style={{ height: "40px" }}
                src={i6}
                alt=""
                data-aos="fade-up"
              />
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Parteners;
